require("trix");

import "bootstrap";
import "select2";
import "stickyfilljs";
import "floatthead";
import "stickytablecolumns";
import "./index.css";
import "./open-iconic/css/open-iconic-bootstrap.min.css";
import "@babel/polyfill";
import "@fortawesome/fontawesome-free/css/all.css";
import "./trix-editor-overrides";
import "./actiontext.scss";

var doc = document.documentElement;
doc.setAttribute("data-useragent", navigator.userAgent);

// Identify when the page is running on IE
$(function() {
  if (
    navigator.userAgent.indexOf("MSIE") > 0 ||
    navigator.userAgent.indexOf("Trident/") > 0
  ) {
    document.body.classList.add("ie_browser");
  }
});
