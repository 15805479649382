/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "init";

if (window.location.pathname.split("/")[1] === "comparatives") {
  import("components/argumentarium-page/argumentarium-page").then();
  import("components/comparative/comparative").then();
} else if (window.location.pathname.indexOf("/admin") >= 0) {
  import("components/admin/admin").then();
  import("init_only_actiontext");
} else {
  import("components/page/page").then();
}
