window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ["image/jpeg", "image/png"];
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert("File types accepted: JPEG, PNG");
  }
  const maxFileSize = 3072 * 2024; // 6.2MB
  if (event.file.size > maxFileSize) {
    event.preventDefault();
    alert("Maximum file size: 6.2MB");
  }
});
